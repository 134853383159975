"use strict";

const modalData = {
    "loginError": {
        "message": {
            "error": "ログインに失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "itemsError": {
        "message": {
            "error": "商品の取得に失敗しました",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "csvError": {
        "message": {
            "error": "csvファイル情報の取得に失敗しました",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "itemDetailFetchError": {
        "message": {
            "error": "商品情報の取得に失敗しました",
        },
        "button": {
            "error": "閉じる",
        },
    },
};

export default modalData;
