"use strict";

import axios from "axios";
import configs from "@/config";
import modal from "@/mixins/modal";
import requests from "@/mixins/request";
import validate from "@/plugins/vee-validate";
import sort from "@/mixins/sort";

export default {
    "$_axios": axios,
    "$_configs": configs,
    "mixins": [
        modal,
        requests,
        validate,
        sort,
    ],
    "methods": {
        $_goBack() {
            this.$router.back();
        },
    },
};
